/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
/* The following block can be included in a custom.scss */
$primary: #FFC800;
$nav-bg: #343a40;
$montserrat: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";


/* make the customizations */
$theme-colors: (
        "info": tomato,
        "danger": teal,
        "primary": $primary,
        "nav": $nav-bg,
);
header {
  color: #fff;
  text-align: center;
  display: grid;
  place-items: center;
  background-image: url("./assets/img/home/bg.jpg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  height: 100vh;
  width: 100%;
}
header .subheading {
  font-size: 2rem;
  font-style: italic;
  line-height: 1.5rem;
  margin-bottom: 25px;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
header .heading {
  font-size: 3.55rem;
  font-weight: 700;
  line-height: 3.25rem;
  margin-bottom: 2rem;
  font-family: $montserrat;
}

#company{
  text-align: center;
  //background-color: brown;
  h2{
    font-weight: bolder;
    font-size: 3rem;
    font-family: $montserrat;
  }
  p{
    font-size: 1.2rem;
    --bs-text-opacity: 1;
    font-style: italic;
    color: #6c757d !important;
    padding-bottom: 4rem;
  }
}
#products{
  text-align: center;
  h2{
    font-weight: bolder;
    font-size: 3rem;
    font-family: $montserrat;
  }
  p{
    font-size: 1.2rem;
    --bs-text-opacity: 1;
    font-style: italic;
    color: #6c757d !important;
    padding-bottom: 4rem;
  }
}

.btn-primary,
.btn-primary:disabled,
.btn-primary:hover,
.btn-primary:focus,
{
  background-color: $primary;
  border-color: $primary;
  padding: 14px 28px;
  font-weight: bold;
  font-size: 1.4rem;
}
.btn-primary:focus,
.btn-primary:disabled,
.btn-primary:hover{
  box-shadow: 0 0 0 0.25rem rgb(255 200 0 / 50%);
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus,
.btn-primary:active:focus, .btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgb(255 200 0 / 50%);
}
.dropdown-menu .dropdown-item {
  color: #dee2e6;
  font-weight: bold;
  background-color: $nav-bg;
}

.dropdown-menu .dropdown-item.active {
  background-color: rgb($primary, 0.75);
}

.navbar-dark {
  background-color: $nav-bg;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link.active {
  color: $primary;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgb($primary, 0.75);
}


.navbar-light .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link {
  color: rgb(255, 255, 255, 0.75);
  font-weight: bold;
}
.navbar>.container{
  padding: 6px 0;
}
.navbar-light .navbar-brand,
.navbar-dark .navbar-brand {
  color: $primary;
  display: flex;
  justify-content: center;
  h2{
    font-size: 1.6rem;
    padding-left: 8px;
    margin: 0;
  }
  .brand-first{
    padding-left: 10px;
    font-weight: bold;
  }
}


.navbar-dark .navbar-brand:active,
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus,
.navbar-light .navbar-brand:active,
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: $primary;
}
.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
}

.page-section {
  padding: 6rem 0;
}
.page-section h2.section-heading, .page-section .section-heading.h2 {
  font-size: 2.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
}
#gallery{
  h2{
    font-weight: 800;
    font-family: $montserrat;
  }
}
.page-section h3.section-subheading, .page-section .section-subheading.h3 {
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 4rem;
}

.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}
.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  content: "";
  background-color: #e9ecef;
}
.timeline > li {
  position: relative;
  min-height: 50px;
  margin-bottom: 50px;
}
.timeline > li:after, .timeline > li:before {
  display: table;
  content: " ";
}
.timeline > li:after {
  clear: both;
}
.timeline > li .timeline-panel {
  position: relative;
  float: right;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
}
.timeline > li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}
.timeline > li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}
.timeline > li .timeline-image {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 100px;
  height: 100px;
  margin-left: 0;
  text-align: center;
  color: white;
  border: 7px solid #e9ecef;
  border-radius: 100%;
  background-color: #ffc800;
}
.timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
}
.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}
.timeline > li:last-child {
  margin-bottom: 0;
}
.timeline .timeline-heading h4, .timeline .timeline-heading .h4 {
  margin-top: 0;
  color: inherit;
}
.timeline .timeline-heading h4.subheading, .timeline .timeline-heading .subheading.h4 {
  text-transform: none;
}
.timeline .timeline-body > ul,
.timeline .timeline-body > p {
  margin-bottom: 0;
}
.timeline-heading h4{
  font-weight: 800;
}
ol,
ul {
  padding-left: 2rem;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .timeline:before {
    left: 50%;
  }
  .timeline > li {
    min-height: 100px;
    margin-bottom: 100px;
  }
  .timeline > li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0 20px 20px 30px;
    text-align: right;
  }
  .timeline > li .timeline-image {
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 16px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 0 30px 20px 20px;
    text-align: left;
  }
}
@media (min-width: 992px) {
  .timeline > li {
    min-height: 150px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px;
  }
  .timeline > li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 30px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 20px 20px;
  }
}
@media (min-width: 1200px) {
  .timeline > li {
    min-height: 170px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px 100px;
  }
  .timeline > li .timeline-image {
    width: 170px;
    height: 170px;
    margin-left: -85px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    margin-top: 40px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 100px 20px 20px;
  }
}

 .portfolio-item {
  max-width: 26rem;
  margin-left: auto;
  margin-right: auto;
}
 .portfolio-item .portfolio-link {
  position: relative;
  display: block;
  margin: 0 auto;
}
 .portfolio-item .portfolio-link .portfolio-hover {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 200, 0, 0.9);
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity ease-in-out 0.25s;
}
.portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content {
  font-size: 1.25rem;
  color: white;
}
.portfolio-item .portfolio-link:hover .portfolio-hover {
  opacity: 1;
}
 .portfolio-item .portfolio-caption {
  padding: 1.5rem;
  text-align: center;
  background-color: #fff;
}
.portfolio-item .portfolio-caption .portfolio-caption-heading {
  font-size: 1.5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  margin-bottom: 0;
}
.portfolio-item .portfolio-caption .portfolio-caption-subheading {
  font-style: italic;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
section#contacts {
  background-color: #212529;
  background-image: url("./assets/img/map-image.png");
  background-repeat: no-repeat;
  background-position: center;
}
section#contacts .section-heading {
  color: #fff;
}
section#contacts form#contactForm .form-group {
  margin-bottom: 1.5rem;
}
section#contacts form#contactForm .form-group input,
section#contacts form#contactForm .form-group textarea {
  padding: 1.25rem;
}
section#contacts form#contactForm .form-group input.form-control {
  height: auto;
}
section#contacts form#contactForm .form-group-textarea {
  height: 100%;
}
section#contacts form#contactForm .form-group-textarea textarea {
  height: 100%;
  min-height: 10rem;
}
section#contacts form#contactForm p.help-block {
  margin: 0;
}
section#contacts form#contactForm .form-control:focus {
  border-color: #ffc800;
  box-shadow: none;
}
section#contacts form#contactForm ::-webkit-input-placeholder {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #ced4da;
}
section#contacts form#contactForm :-moz-placeholder {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #ced4da;
}
section#contacts form#contactForm ::-moz-placeholder {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #ced4da;
}
section#contacts form#contactForm :-ms-input-placeholder {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #ced4da;
}
#contacts h2{
  font-weight: 700;
}